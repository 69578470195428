.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#dashboard-sidebar{
  width: 18vw;
  flex-shrink: 0;
  background-color: #f7f8f9;
  height: 100vh;
}

 #dashboard-sidebar .css-12i7wg6-MuiPaper-root-MuiDrawer-paper{
  background: none ;
  border: none;
  color: black;
  width: 18vw;
  height: 100vh;

}

#dashboard-sidebar .css-1l8j5k8{
  background-color: #f7f8f9 !important;
}

#dashboard-sidebar ul{
  margin-top: 5vh;
}

.dashboard-logo{
  width: 50%;
  margin: 40px 0 0 30px;
  filter: brightness(1) !important;
}


/* UserTable.css */
.table-container {
margin-top: 4vh;
max-height: 80vh;
overflow: auto;
scrollbar-width: thin;
scrollbar-color: #888 #f5f5f5;
}

.table-heading th {
font-weight: bold;
}

.modal-content {
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
background-color: white;
padding: 20px;
border: 2px solid #000;
}

#mdv-form-table td:first-child{
width: 20%;
}


#mdv-form-table td{
width: 0px;
}




.table-border,  .table-border td,  .table-border th  {
  border: 1px solid black;
  border-collapse: collapse;
}

.table-bottom-border td{
  border-bottom: 2px solid;
}

.table-bottom-border td:last-child{
  text-align: right;
}

.table-border, .table-bottom-border{
  width: 100%;
}

.bold{
  font-weight: bold;
}

.signature .line{
  margin-top: 100px;
  border-bottom: 2px solid;
  width: 30%;
}

.invoice-img-box{
  width: 100%;
  height: 80vh;
  overflow-y: auto;
  background-color: rgb(199, 197, 197);
}

.image-scroll-container {
  display: flex;
  flex-direction: column;
}

.image-scroll-container img {
  width: 100%;
  height: 80vh;
  margin-bottom: 10px; /* Adjust the margin between images */
}

td, tr{
  padding-left: 10px;
}

.loader{
  background-color:rgba(255, 255, 255, 0.701);
  opacity: 90%;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
}

.loader img{
  z-index: 5001;
  position: relative;
  left: 40%;
  top: 47%;
  width: 8%;
}