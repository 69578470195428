.welcome-text {
  color: #233548;
  letter-spacing: -0.333333px !important;
  /* font-family: "DM Sans" !important; */
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 30px !important;
  line-height: 39px !important;
}
.field{
    background: #FFFFFF !important;
    border: 1px solid #ECECEC !important;
    box-shadow: 0px 5px 20px -3px rgba(0, 0, 0, 0.05) !important;
    border-radius: 12px !important;
    align-items: center;
        
}